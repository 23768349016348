import React, { useEffect, useState } from "react";
import "./AuthorExcursions.scss";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const AuthorExcursions = () => {
  const [authorData, setAuthorData] = useState();
  async function getAuthorExcursionData() {
    await $api.get(`/authors-tour/get`).then((response) => {
      setAuthorData(response.data[0]);
    });
  }

  useEffect(() => {
    getAuthorExcursionData();
  }, []);
  return (
    <div className="author_excursions">
      <Helmet>
        <title>Авторские экскурсии по миру</title>
        <meta name="description" content="экскурсии по миру" />
      </Helmet>
      <TitleDesc title={"Авторские экскурсии по миру"} />
      <div className="author_excursion_card containerXL">
        {authorData?.AuthorsToursAroundTheWorldItems.map((item) => {
          return (
            <FullWidthCard
              key={item?.id}
              data={item}
              hideButton={true}
              size={"small"}
            />
          );
        })}
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">АВТОРСКИЕ ЭКСКУРСИИ ПО МИРУ</NavLink>
      </div>
    </div>
  );
};

export default AuthorExcursions;
