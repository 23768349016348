import React from "react";
import "./CountryGeo.scss";

const CountryGeo = ({ image, geoTitle, geodescription }) => {
  return (
    <div className="main_geo container">
      <div className="image_section">
        <img src={image} alt="" />
      </div>
      <div className="main_geo_info">
        <h3>{geoTitle}</h3>
        <p>{geodescription}</p>
      </div>
    </div>
  );
};

export default CountryGeo;
