import React, { useState, useEffect } from "react";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import Button from "../../command/button/Button";
import "./Reviews.scss";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import AddReviewModal from "../../components/addReviewModal/AddReviewModal";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const Reviews = () => {
  const [showModal, setShowModal] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);

  const getReviewsData = async () => {
    const { data } = await $api.get("/reviews/active");
    setReviewsData(data);
  };

  useEffect(() => {
    getReviewsData();
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  return (
    <div className="reviews_page">
      <Helmet>
        <title>ОТЗЫВЫ</title>
        <meta
          name="description"
          content="Наиболее посещаемые курорты, города и пункты назначения на турах по Армении,которые включены в наши туристические маршруты."
        />
      </Helmet>
      <div className="title_section">
        <TitleDesc
          title="ОТЗЫВЫ"
          description="Наиболее посещаемые курорты, города и пункты назначения на турах по Армении,которые включены в наши туристические маршруты."
        />
        <Button
          variant={"primary"}
          name="Оставить отзыв"
          size={"large"}
          onClick={() => setShowModal(!showModal)}
        />
      </div>
      <div className="card_section">
        {reviewsData?.map((item) => {
          return (
            <FullWidthCard
              key={item?.id}
              data={item}
              variant={"small"}
              hideButton={true}
            />
          );
        })}
      </div>
      <div className="modal_div">
        {showModal ? <AddReviewModal setShowModal={setShowModal} /> : null}
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">ОТЗЫВЫ</NavLink>
      </div>
    </div>
  );
};

export default Reviews;
