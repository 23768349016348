import React, { useEffect, useState } from "react";
import "./Header.scss";
import ArrowRightIcon from "./../../command/icons/ArrowRightIcon";
import ArrowTopIcon from "../../command/icons/ArrowTopIcon";
import { useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../../command/icons/Hamburger";
import $api from "../../http";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import Mainlogo from "../../command/icons/Mainlogo";
const Header = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const [headerFields, setHeaderFields] = useState(null);
  const [country, setCountry] = useState([]);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  function scrolling() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrollTop(window.pageYOffset > 2)
      );
    }
  }

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  async function getHeaderFields() {
    await $api.get(`/info/header`).then((response) => {
      setHeaderFields(response.data.header);
    });
  }

  async function getCountries() {
    await $api.post(`/countrie`).then((response) => {
      setCountry(response.data);
    });
  }

  const onNavigation = (id) => {
    navigation(`/country/${id}`);
  };

  const refFirst = useOutsideClick(() => {
    setShowDropdown(false);
  });

  const refSecond = useOutsideClick(() => {
    setIsMenuOpen(false);
  });

  useEffect(() => {
    scrolling();
    getHeaderFields();
    getCountries();
  }, []);

  return (
    <div
      className={`header ${scrollTop ? "header_onscroll" : ""} ${
        location.pathname === "/reviews" ||
        location.pathname === "/all-countries" ||
        location.pathname === "/other-excursions-in-armenia" ||
        location.pathname === "/other-tours-in-armenia" ||
        location.pathname === "/author-excursions"
          ? "active"
          : ""
      } `}
    >
      <div
        className="left_section"
        onClick={() => {
          navigation("/");
        }}
      >
        <div>
          <Mainlogo />
        </div>
      </div>
      <div className="right_section">
        <div
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          className="header_field"
          ref={refFirst}
        >
          <p>{headerFields?.countries}</p>
          <div className={showDropdown ? "open" : "close"}>
            <ArrowTopIcon />
          </div>
          {showDropdown && (
            <div className="country_dropdown_section">
              {country.map((item) => {
                return item?.active ? (
                  <div
                    className="country_item"
                    key={item.id}
                    onClick={() => {
                      onNavigation(item.id);
                    }}
                  >
                    <div className="country_item_name">
                      <div className="country_item_name_icon">
                        <img src={item.flag} alt="flag" />
                      </div>
                      {item.name.length > 12 ? (
                        <div className="name_text">
                          <p>{item.name.substring(12, "") + "..."}</p>
                          <div className="hovered_full_name">{item.name}</div>
                        </div>
                      ) : (
                        <p>{item.name}</p>
                      )}
                    </div>
                    <div className="country_item_details">
                      {item.visa ? <h5>Visa</h5> : ""}
                      <ArrowRightIcon />
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </div>
        <div
          onClick={() => {
            navigation("/armenia");
          }}
        >
          <p>{headerFields?.currentCountry}</p>
        </div>
        <div
          onClick={() => {
            navigation("/reviews");
          }}
        >
          <p>{headerFields?.feedback}</p>
        </div>
        <div
          onClick={() => {
            navigation("/about-us");
          }}
        >
          <p>{headerFields?.aboutUs}</p>
        </div>
      </div>
      {isMenuOpen && (
        <div ref={refSecond}>
          <HamburgerMenu isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>
      )}
      <div
        className={`hamburger_menu ${
          location.pathname === "/reviews" ? "hamburger" : ""
        }`}
        onClick={toggleMenu}
      >
        <Hamburger />
      </div>
    </div>
  );
};

export default Header;
