import React from "react";
import "./TitleDesc.scss";

const TitleDesc = ({ title, description }) => {
  return (
    <div className="TitleDesc_section">
      <div className="TitleDesc_title">{title}</div>
      <div className="TitleDesc_description">{description}</div>
    </div>
  );
};

export default TitleDesc;
