import React from "react";
import "./FullWidthCard.scss";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Button from "../../command/button/Button";
import useWindowSize from "../../hooks/useWindowSize";
const FullWidthCard = ({ data, hideButton, size, formRef, onSeletcTur }) => {
  const OnCardButtonClick = () => {
    onSeletcTur(data?.title);
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const windowSize = useWindowSize();
  return (
    <div className="FullWidthCard_sec">
      <div className="FullWidthCard_sec_img_section">
        <img
          src={
            data?.images ||
            data?.image ||
            data?.backgroundName ||
            data?.background
          }
          alt="city"
        />
        {data?.price && data?.duration ? (
          <div className="FullWidthCard_sec_img_section_trasparentDiv">
            <div className="FullWidthCard_sec_img_section_trasparentDiv_text">
              <p className="FullWidthCard_sec_img_section_trasparentDiv_text_price">
                {data?.price}
              </p>
              <p className="FullWidthCard_sec_img_section_trasparentDiv_text_duration">
                {data?.duration}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="FullWidthCard_sec_info">
        <div className="FullWidthCard_sec_info_details">
          <div className="FullWidthCard_sec_info_details_title">
            <h3>{data?.title || data?.name}</h3>
            {data?.flag ? (
              <div className="FullWidthCard_sec_info_details_title_flag">
                <img src={data?.flag} alt=" " />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="FullWidthCard_sec_info_details_desc">
            {windowSize?.width < 600 &&
            !data?.armCurrentCardItemSubs?.length &&
            !data?.armCurrentTourCardItemSubs?.length ? (
              <div>
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Читать далее"}
                  readLessText={"Читать меньше"}
                >
                  {data?.description || data?.review}
                </ReactReadMoreReadLess>
                <div className="list_with_dots">
                  {data?.armCurrentCardItemSubs && (
                    <ul>
                      {data?.armCurrentCardItemSubs?.map((item, index) => {
                        return item?.text ? (
                          <li key={index}>
                            {" "}
                            <span> {item?.text} </span>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <>
                <p>{data?.description || data?.review}</p>
                <div className="list_with_dots">
                  {data?.armCurrentCardItemSubs && (
                    <ul>
                      {data?.armCurrentCardItemSubs?.map((item, index) => {
                        return item?.text ? (
                          <li key={index}>
                            {" "}
                            <span> {item.text} </span>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )}
                  {data?.armCurrentTourCardItemSubs && (
                    <ul>
                      {data?.armCurrentTourCardItemSubs?.map((item, index) => {
                        return item?.text ? (
                          <li key={index}>
                            {" "}
                            <span> {item.text} </span>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {!hideButton ? (
          <div className="FullWidthCard_sec_info_action">
            <Button
              variant={"primary"}
              size={size ? size : "large"}
              name="Выбрать тур"
              onClick={() => OnCardButtonClick()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FullWidthCard;
