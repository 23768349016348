import React from "react";
import "./DescSection.scss";

const DescSection = ({ text }) => {
  return (
    <div className="desc_main">
      <p>{text}</p>
    </div>
  );
};

export default DescSection;
