import React from "react";
import { Route, Routes } from "react-router-dom";
import PageLayout from "../components/pageLayout/PageLayout";
import Homepage from "../pages/homepage/Homepage";
import Country from "../pages/country/Country";
import TicketSearch from "../pages/ticketsearch/TicketSearch";
import SkiHoliday from "../pages/skiHoliday/SkiHoliday";
import HolidayInsurance from "../pages/holidayInsurance/HolidayInsurance";
import HolidayWithKids from "../pages/holidayWithKids/HolidayWithKids";
import Hotels from "../pages/hotels/Hotels";
import Transfer from "../pages/transfer/Transfer";
import Reviews from "../pages/reviews/Reviews";
import CorporativeVip from "../pages/corporativeAndVip/CorporativeVip";
import HolidaySeasonality from "../pages/holidaySeasonality/HolidaySeasonality";
import AboutUs from "../pages/aboutUs/AboutUs";
import AllCountries from "../pages/allCountries/AllCountries";
import AboutArmenia from "../pages/aboutArmenia/AboutArmenia";
import ExcursionsInArmenia from "../pages/excursionsInArmenia/ExcursionsInArmenia";
import ToursInArmenia from "../pages/toursInArmenia/ToursInArmenia";
import { AllExcursions } from "../pages/allExcursions/AllExcursions";
import AllCruises from "../pages/allCruises/AllCruises";
import AuthorExcursions from "../pages/authorExcursions/AuthorExcursions";
import OtherExcursionsInArmenia from "../pages/otherExcursionsInArmenia/OtherExcursionsInArmenia";
import OtherToursInArmenia from "../pages/otherToursInArmenia/OtherToursInArmenia";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<Homepage />} />
        <Route path="/country/:id" element={<Country />} />
        <Route path="/services/1" element={<TicketSearch />} />
        <Route path="/services/2" element={<Hotels />} />
        <Route path="/services/5" element={<SkiHoliday />} />
        <Route path="/services/7" element={<HolidayInsurance />} />
        <Route path="/services/6" element={<HolidayWithKids />} />
        <Route path="/services/3" element={<Transfer />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/services/4" element={<CorporativeVip />} />
        <Route path="/services/8" element={<HolidaySeasonality />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/all-countries" element={<AllCountries />} />
        <Route path="/all-excursions" element={<AllExcursions />} />
        <Route path="/all-cruises" element={<AllCruises />} />
        <Route path="/armenia" element={<AboutArmenia />} />
        <Route
          path="/other-excursions-in-armenia"
          element={<OtherExcursionsInArmenia />}
        />
        <Route
          path="/other-tours-in-armenia"
          element={<OtherToursInArmenia />}
        />
        <Route
          path="/armenia/excursions-in-armenia/:id"
          element={<ExcursionsInArmenia />}
        />
        <Route
          path="/armenia/tours-in-armenia/:id"
          element={<ToursInArmenia />}
        />
        <Route path="/author-excursions" element={<AuthorExcursions />} />
      </Route>
    </Routes>
  );
};

export default Router;
