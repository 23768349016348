import React from "react";

const Hamburger = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.29688 19.1406H20.7031M4.29688 12.8906H20.7031M4.29688 6.64062H20.7031"
        stroke="#278084"
        strokeWidth="2.34375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Hamburger;
