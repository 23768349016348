import React, { useEffect, useState } from "react";
import "./ExcursionSection.scss";
import ExcursionCard from "../excursionCard/ExcursionCard";
import Button from "../../command/button/Button";
import TitleDesc from "../TitleDesc/TitleDesc";
import { useNavigate } from "react-router-dom";
import MoreInfoModal from "../moreInfoModal/MoreInfoModal";
const ExcursionSection = ({ homeExcursions, homeData }) => {
  const navigation = useNavigate();

  const handleAllExcursions = () => {
    navigation(`/all-excursions`);
  };

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  const handleCardClick = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  return (
    <div className="excursion_section container">
      <TitleDesc
        title={homeData?.titleOfTheSecondSection}
        description={homeData?.descriptionOfTheSecondSection}
      />

      <div className="excursion_card_section">
        {homeExcursions.map((item, index) => {
          return (
            <ExcursionCard
              key={index}
              data={item}
              setShowModal={setShowModal}
              showModal={showModal}
              onNavigation={() => handleCardClick(item)}
            />
          );
        })}
      </div>
      <div className="button_section">
        <Button
          variant={"primary"}
          name="Все экскурсии"
          size={"large"}
          onClick={handleAllExcursions}
        />
      </div>
      <div className="modal_div">
        {showModal ? (
          <MoreInfoModal setShowModal={setShowModal} data={modalData} />
        ) : null}
      </div>
    </div>
  );
};

export default ExcursionSection;
