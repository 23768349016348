import React from "react";
import "./SmallVerticalCards.scss";
import Button from "../../command/button/Button";
const SmallVerticalCards = ({ data, onClick }) => {
  return (
    <div className="vertical_small_card">
      <div className="vertical_small_card_details transparent_div">
        <div className="vertical_small_card_details_content">
          <div className="vertical_small_card_details_content_header">
            <h1 className="vertical_small_card_details_content_header_title">
              {data?.title?.length > 30 ? (
                <>{data?.title.substring(30, "") + "..."}</>
              ) : (
                <>{data?.title}</>
              )}
            </h1>
          </div>
          <div className="vertical_small_card_details_content_body">
            <div className="vertical_small_card_details_content_body_info">
              <p className="vertical_small_card_details_content_body_info_desc">
                {data?.description.length > 70 ? (
                  <>{data?.description.substring(70, "") + "..."}</>
                ) : (
                  <>{data?.description}</>
                )}
              </p>
              <p className="vertical_small_card_details_content_body_info_price">
                {data?.detailsPrice}
              </p>
            </div>
            <div className="vertical_small_card_details_content_body_action">
              <Button
                variant={"secondary"}
                name="Узнать подробнее"
                size={"full_btn"}
                onClick={onClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="vertical_small_card_image">
        <img src={data?.images} alt="country" />
      </div>
    </div>
  );
};

export default SmallVerticalCards;
