import React from "react";
import "./HeroSection.scss";
import SelectTourForm from "../selectTourForm/SelectTourForm";

const HeroSection = ({ heroBackground }) => {
  function cardMedia() {
    if (
      heroBackground.includes(".jpg") ||
      heroBackground.includes(".webp") ||
      heroBackground.includes(".jpeg") ||
      heroBackground.includes(".gif") ||
      heroBackground.includes(".png")
    ) {
      return <img src={heroBackground} alt="country" />;
    } else {
      return (
        <video src={heroBackground} loop muted autoPlay playsInline></video>
      );
    }
  }
  return (
    <div className="hero_section">
      {cardMedia()}
      <div className="form_section">
        <SelectTourForm selects={true} />
      </div>
    </div>
  );
};

export default HeroSection;
