import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.00109863 20.6117C-0.00109863 30.5567 7.22057 38.8233 16.6656 40.5V26.055H11.6656V20.5H16.6656V16.055C16.6656 11.055 19.8872 8.27833 24.4439 8.27833C25.8872 8.27833 27.4439 8.5 28.8872 8.72167V13.8333H26.3322C23.8872 13.8333 23.3322 15.055 23.3322 16.6117V20.5H28.6656L27.7772 26.055H23.3322V40.5C32.7772 38.8233 39.9989 30.5567 39.9989 20.6117C39.9989 9.55 30.9989 0.5 19.9989 0.5C8.9989 0.5 -0.00109863 9.55 -0.00109863 20.6117Z"
        fill="#278084"
      />
    </svg>
  );
};

export default FacebookIcon;
