import React from "react";
import "./SeasonesTable.scss";

const SeasonesTable = ({ title, data }) => {
  return (
    <div className="seasones_table">
      <div className="seasones_table_header">
        <h3 className="seasones_table_header_title">{title}</h3>
      </div>
      <div className="seasones_table_content">
        <table className="seasones_table_content_table">
          <thead>
            <tr>
              <th className="fixed-item">Страна</th>
              <th>Январь</th>
              <th>Февраль</th>
              <th>Март</th>
              <th>Апрель</th>
              <th>Май</th>
              <th>Июнь</th>
              <th>Июль</th>
              <th>Август</th>
              <th>Сентябрь</th>
              <th>Октябрь</th>
              <th>Ноябрь</th>
              <th>Декабрь</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => {
              return (
                <tr key={idx} className="main_rows">
                  {Object.entries(item).map(([key, value], index) => {
                    return key === "Country" ? (
                      <th className="table_cell fixed-item" key={index}>
                        {value}
                      </th>
                    ) : (
                      <td
                        key={index}
                        className={` table_cell ${
                          value === "Горнолыжный"
                            ? "blue"
                            : value === "Экскурсионный"
                            ? "green"
                            : value === "Пляжный"
                            ? "yellow"
                            : "table_cell"
                        }`}
                      >
                        {/* {value} */}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeasonesTable;
