import React from "react";
import "./HeroBgName.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HeroBgName = ({
  name,
  background,
  slider,
  backgrounds,
  currentCountry,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="country_hero_section">
      {!slider ? (
        <div className="image_section">
          {background ? (
            <img src={background} alt="" />
          ) : (
            <>
              {backgrounds?.map((item, index) => {
                return (
                  <img src={item?.backgroundName} alt="nkar" key={index} />
                );
              })}
            </>
          )}
          <div className="transparent_div" />
        </div>
      ) : (
        <Slider {...settings}>
          {backgrounds?.map((background, index) => (
            <div key={index} className="image_section">
              {background?.background ? (
                <img src={background?.background} alt="" />
              ) : background?.backgroundName ? (
                <img src={background?.backgroundName} alt="" />
              ) : (
                <img src={background} alt="" />
              )}
              <div className="transparent_div" />
            </div>
          ))}
        </Slider>
      )}

      <div className="country_name">
        <span>{name}</span>
      </div>
    </div>
  );
};

export default HeroBgName;
