import React, { useEffect, useState } from "react";
import "./AllCruises.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import ExcursionCard from "../../components/excursionCard/ExcursionCard";
import MoreInfoModal from "../../components/moreInfoModal/MoreInfoModal";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const AllCruises = () => {
  const [allCruise, setAllCruise] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  const handleCardClick = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  async function getAllCruises() {
    await $api.get(`cruise/get-all-cruise-data`).then((response) => {
      setAllCruise(response.data[0]);
    });
  }

  useEffect(() => {
    getAllCruises();
  }, []);
  return (
    <div className="all-cruises">
      <Helmet>
        <title>{allCruise?.title}</title>
        <meta name="description" content={allCruise?.firstDesc} />
      </Helmet>
      <div className="hero_sec">
        <HeroBgName
          name={allCruise?.title}
          background={allCruise?.allCruisesBackground?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{allCruise?.title}</NavLink>
        </div>
      </div>
      <div className="container">
        <div className="desc_section">
          <DescSection text={allCruise?.firstDesc} />
        </div>
        <div className="excursion_card_section">
          {allCruise?.gastroCruises?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={() => handleCardClick(item)}
              />
            );
          })}
        </div>
        <div className="desc_section">
          <DescSection text={allCruise?.secondDesc} />
        </div>
        <div className="excursion_card_section">
          {allCruise?.worldCruises?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={() => handleCardClick(item)}
              />
            );
          })}
        </div>
        <div className="desc_section">
          <DescSection text={allCruise?.thirdDesc} />
        </div>
        <div className="excursion_card_section">
          {allCruise?.thirdSectionCruises?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={() => handleCardClick(item)}
              />
            );
          })}
        </div>
      </div>
      <div className="modal_div">
        {showModal ? (
          <MoreInfoModal setShowModal={setShowModal} data={modalData} />
        ) : null}
      </div>
    </div>
  );
};

export default AllCruises;
