import React from "react";

const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
    >
      <path
        d="M50.6649 50.6667L37.9982 38L25.3315 50.6667"
        stroke="#278084"
        strokeWidth="3.65385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 38V66.5"
        stroke="#278084"
        strokeWidth="3.65385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.567 58.235C67.6556 56.5512 70.0954 53.8868 71.5016 50.6623C72.9077 47.4379 73.2 43.8369 72.3323 40.4279C71.4646 37.0188 69.4864 33.9958 66.7098 31.8359C63.9332 29.6761 60.5164 28.5024 56.9986 28.5H53.0086C52.0501 24.7926 50.2637 21.3508 47.7835 18.4332C45.3033 15.5156 42.194 13.1982 38.6893 11.6553C35.1846 10.1124 31.3757 9.384 27.549 9.52499C23.7223 9.66598 19.9774 10.6726 16.5957 12.4693C13.2141 14.266 10.2837 16.8059 8.02496 19.898C5.76621 22.9902 4.23784 26.5542 3.55476 30.3221C2.87167 34.09 3.05165 37.9637 4.08116 41.652C5.11067 45.3403 6.96292 48.7473 9.49865 51.6167"
        stroke="#278084"
        strokeWidth="3.65385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6649 50.6667L37.9982 38L25.3315 50.6667"
        stroke="#278084"
        strokeWidth="3.65385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
