import React from "react";

const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="13"
      viewBox="0 0 29 13"
      fill="none"
    >
      <path
        d="M23.1096 12.3902L21.7352 10.9667L25.2203 7.48153H0.333374V5.51804H25.2203L21.7352 2.03289L23.1096 0.609375L29 6.49978L23.1096 12.3902Z"
        fill="#686868"
      />
    </svg>
  );
};

export default ArrowRightIcon;
