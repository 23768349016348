import React from "react";

const ChevronDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="14"
      viewBox="0 0 25 14"
      fill="none"
    >
      <path
        d="M8 7L13.2083 12.2083L18.4167 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDownIcon;
