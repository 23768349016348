import React, { useEffect, useState } from "react";
import "./HamburgerMenu.scss";
import ArrowRightIcon from "./../../command/icons/ArrowRightIcon";
import { useNavigate } from "react-router-dom";
import WhatsappIcon from "../../command/icons/WhatsappIcon";
import TelegramIcon from "../../command/icons/TelegramIcon";
import Vkicon from "../../command/icons/Vkicon";
import FacebookIcon from "../../command/icons/FacebookIcon";
import InstagramIcon from "../../command/icons/InstagramIcon";
import $api from "../../http";

const HamburgerMenu = ({ isOpen, setIsMenuOpen }) => {
  const navigation = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [country, setCountry] = useState([]);

  const closeMenu = () => {
    setIsMenuOpen(false);
    setShowDropdown(false);
  };
  const onNavigation = (id) => {
    navigation(`/country/${id}`);
  };
  async function getCountries() {
    await $api.post(`/countrie`).then((response) => {
      setCountry(response.data);
    });
  }
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className={`hamburger-menu ${isOpen ? "open" : ""}`}>
      <div className="close-button" onClick={closeMenu}>
        &#10005;
      </div>

      <div className={`country_dropdown ${showDropdown ? "open" : ""}`}>
        {country.map((item) => {
          return item?.active ? (
            <div
              className={`country_items ${!item.visa ? "items" : ""}`}
              key={item.id}
              onClick={() => {
                onNavigation(item.id);
                closeMenu();
              }}
            >
              <div className="country_items_names">
                <div className="country_items_names_icons">
                  <img src={item.flag} alt="flags" />
                </div>
                <p>{item.name}</p>
              </div>
              <div className="country_items_info">
                {item.visa ? <h5>Visa</h5> : ""}
                <ArrowRightIcon />
              </div>
            </div>
          ) : null;
        })}
      </div>

      <div className="menu">
        <ul>
          <li
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <p>Страны</p>
          </li>

          <li
            onClick={() => {
              navigation("/armenia");
              closeMenu();
            }}
          >
            <p>Армения</p>
          </li>
          <li
            onClick={() => {
              navigation("/reviews");
              closeMenu();
            }}
          >
            <p>Отзывы</p>
          </li>
          <li
            onClick={() => {
              navigation("/about-us");
              closeMenu();
            }}
          >
            <p>О Компании</p>
          </li>
        </ul>
      </div>
      <div className="social_networks_section">
        <div className="top_section">
          <a href="https://wa.me/79626693127">
            <WhatsappIcon />
          </a>
          <a href="https://t.me/lada_travel_armenia">
            <TelegramIcon />
          </a>
          <a href="https://vk.com/tur_otpusk_ladatravel">
            <Vkicon />
          </a>
        </div>
        <hr />
        <div className="bottom_section">
          <a href="https://www.facebook.com/ladatravell?mibextid=LQQJ4d">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/lada_travel_agency/">
            <InstagramIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
