import React from "react";
import "./Textarea.scss";
const Textarea = ({ variant, placeholder, onChange }) => {
  return (
    <div
      className={` custom_textarea  ${variant === "white" ? "white_bg" : ""}  `}
    >
      <textarea placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default Textarea;
