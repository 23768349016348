import React, { useState } from "react";
import "./AddReviewModal.scss";
import Input from "../../command/input/Input";
import Textarea from "../../command/textarea/Textarea";
import UploadIcon from "../../command/icons/UploadIcon";
import CloseIcon from "./../../command/icons/CloseIcon";
import $api from "../../http";
import { toast } from "react-toastify";

const AddReviewModal = ({ setShowModal }) => {
  const [active, setActive] = useState(false);
  const [image, setImage] = useState(null);
  const [review, setReview] = useState("");
  const [nameError, setNameError] = useState("");
  const [reviewError, setReviewError] = useState("");
  const [imageError, setImageError] = useState("");
  const [nameField, setNameField] = useState("");
  const getImage = (file) => {
    if (file.length) {
      setImage(file[0]);
      setImageError("");
    }
  };

  const removeImage = () => {
    setImage(null);
    setImageError("");
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const notify = () => toast.success("Ваш отзыв принят!");
  const handleSubmit = async () => {
    let isValid = true;
    if (nameField.trim() === "") {
      setNameError("Здесь должно быть ваше имя!");
      isValid = false;
    } else {
      setNameError("");
    }
    if (review.trim() === "") {
      setReviewError("Здесь должен быть ваш отзыв!");
      isValid = false;
    } else {
      setReviewError("");
    }
    if (!image) {
      setImageError("Добавьте фотографию!");
      isValid = false;
    } else {
      setImageError("");
    }
    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append("name", nameField);
    formData.append("review", review);
    formData.append("file", image);
    const { data } = await $api.post("/reviews", formData);
    closeModal();
    notify();
  };

  const handleNameChange = (e) => {
    let input = e.target.value;
    let regex = /^(?![\d ])(?:[a-zA-Z_ ]*|(?:[А-Яа-яЁё]*))$/;
    if (regex.test(input)) {
      setNameField(e.target.value);
    }
  };
  const handleReviewChange = (e) => {
    setReview(e.target.value);
    if (e.target.value.trim() !== "") {
      setReviewError("");
    }
  };
  return (
    <div className="modal">
      <div className="add_review_modal">
        <div className="modall_header">
          <h1>Оставить отзыв</h1>
        </div>
        <div className="modal_content">
          <div className="input">
            <Input
              placeholder="Ваше имя"
              variant={"white"}
              value={nameField}
              onChange={handleNameChange}
            />
            {nameError && <p className="error-message">*{nameError}</p>}
          </div>
          <div className="textarea">
            <Textarea
              placeholder="Ваш отзыв"
              variant={"white"}
              value={review}
              onChange={handleReviewChange}
            />
            {reviewError && <p className="error-message">*{reviewError}</p>}
          </div>
          <div className="upload">
            <div className="upload_file">
              <input
                type="file"
                onChange={(e) => {
                  setActive(!active);
                  getImage(e.target.files);
                }}
                className={`${image ? "no_clickable" : ""}  `}
              />
              <UploadIcon />
              <div className="uploaded_image">
                {image ? (
                  <div className="image">
                    <img src={URL.createObjectURL(image)} alt="img"></img>
                    <div className="close_icon" onClick={removeImage}>
                      <CloseIcon />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {imageError && <p className="error-message">*{imageError}</p>}
          </div>
        </div>
        <div className="modal_footer">
          <button onClick={handleSubmit}>Оставить отзыв</button>
        </div>
        <div className="close_modal" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="close_div" onClick={closeModal}></div>
    </div>
  );
};

export default AddReviewModal;
