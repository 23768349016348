import React from "react";

const WhatsappIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M17.917 0.551998C14.6616 0.974575 11.5471 2.13274 8.83948 3.94824C7.337 4.94989 4.5668 7.7201 3.56514 9.22258C-0.942308 15.9681 -1.06751 24.4352 3.25212 31.2746L3.89381 32.2763L2.65739 35.8604C1.9844 37.8324 1.48358 39.4757 1.53053 39.5227C1.57748 39.5696 3.28343 39.0688 5.34934 38.4114L9.07425 37.222L10.3263 37.9419C16.0232 41.1973 23.16 41.3538 29.1074 38.3645C30.9855 37.4254 32.4879 36.3142 34.2095 34.5926C37.1519 31.6503 38.8891 28.4575 39.7656 24.4039C40.1725 22.5415 40.1725 18.4409 39.7656 16.5785C38.8891 12.5249 37.1519 9.33214 34.2095 6.38977C31.3141 3.49436 28.2779 1.80407 24.3338 0.911968C23.0348 0.614605 19.1534 0.395493 17.917 0.551998ZM13.9417 9.81731C14.3799 10.099 16.571 15.389 16.4458 15.8742C16.3989 16.062 15.9293 16.7193 15.4128 17.3454C14.8964 17.9558 14.4738 18.5348 14.4738 18.6287C14.4738 19.2235 16.3206 21.712 17.7761 23.0892C19.4508 24.6543 22.1427 26.1881 23.2226 26.1881C23.6609 26.1881 23.8487 26.0786 24.2399 25.5777C25.9146 23.4649 25.9928 23.3866 26.4624 23.3397C26.7754 23.3084 27.6675 23.6683 29.4986 24.5917L32.081 25.8908L32.0654 26.8298C32.0497 28.0506 31.7837 28.9583 31.2515 29.5844C30.5629 30.4139 29.123 31.2433 27.9022 31.5251C26.4937 31.8381 25.3668 31.6816 22.7844 30.7895C19.263 29.5844 17.2596 28.3166 14.5364 25.5934C11.5314 22.5884 9.34031 19.3487 8.74558 17.048C8.1978 14.9038 8.65167 12.6032 9.91939 11.0224C10.7332 10.0051 11.3906 9.6921 12.6739 9.6921C13.2843 9.6921 13.8478 9.75471 13.9417 9.81731Z"
        fill="#278084"
      />
    </svg>
  );
};

export default WhatsappIcon;
