import React from "react";
import "./MoreInfoModal.scss";
import CloseIcon from "../../command/icons/CloseIcon";

const MoreInfoModal = ({ setShowModal, data }) => {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="modal">
      <div className="more_modal">
        <h2 className="modal-title">
          {data?.dataValues?.title || data?.name || data?.title}
        </h2>
        <div className="modal-description">
          <p>{data?.description || data?.text || data?.detailsDescription}</p>
        </div>
        <div className="close_modal" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="close_div" onClick={closeModal}></div>
    </div>
  );
};

export default MoreInfoModal;
