import React, { useEffect, useState } from "react";
import "./OtherExcursionsInArmenia.scss";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import ExcursionCard from "../../components/excursionCard/ExcursionCard";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const OtherExcursionsInArmenia = () => {
  const [allArmeniaExcursion, setAllArmeniaExcursion] = useState();
  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  async function getAllArmeniaExcursionData() {
    await $api.get(`armenia/get-armenia-excursion-data`).then((response) => {
      setAllArmeniaExcursion(response.data);
    });
  }

  useEffect(() => {
    getAllArmeniaExcursionData();
  }, []);

  const excursionNavigation = (id) => {
    navigation(`/armenia/excursions-in-armenia/${id}`);
  };
  return (
    <div className="global_page">
      <Helmet>
        <title>ДРУГИЕ ЭКСКУРСИИ В АРМЕНИИ</title>
        <meta name="description" content="СКАЗОЧНЫЙ ОТДЫХ В АРМЕНИИ" />
      </Helmet>
      <div className="other_excursions_in_armenia container">
        <TitleDesc title={"ДРУГИЕ ЭКСКУРСИИ В АРМЕНИИ"} />
        <div className="card_section container">
          {allArmeniaExcursion?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={excursionNavigation}
              />
            );
          })}
        </div>
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink to="/armenia" className="home_nav">
          Армения
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">ДРУГИЕ ЭКСКУРСИИ В АРМЕНИИ</NavLink>
      </div>
    </div>
  );
};

export default OtherExcursionsInArmenia;
