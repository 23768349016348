import React, { useEffect, useState } from "react";
import "./Hotels.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import FullWidthCard from "./../../components/fullWidthCard/FullWidthCard";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const Hotels = () => {
  const [hotelsData, setHotelsData] = useState(null);
  const [hotels, setHotels] = useState();
  async function getHotelsData() {
    await $api.get(`/hotel`).then((response) => {
      setHotelsData(response.data);
    });
  }
  async function getHotels() {
    await $api.get(`/hotel/unusual-hotel`).then((response) => {
      setHotels(response.data);
    });
  }

  useEffect(() => {
    getHotelsData();
    getHotels();
  }, []);
  return (
    <div className="hotels_page">
      <Helmet>
        <title>{hotelsData?.title}</title>
        <meta name="description" content={hotelsData?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          background={hotelsData?.backgroundName}
          name={hotelsData?.title}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{hotelsData?.title}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={hotelsData?.description} />
      </div>
      <div className="choose_toure_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
      <div className="title_section">
        <TitleDesc title={hotelsData?.topUnusualHotelsInTheWoirldTitle} />
      </div>
      <div className="card_section">
        {hotels?.map((item) => {
          return (
            <FullWidthCard
              key={item.id}
              data={item}
              variant={"small"}
              hideButton
            />
          );
        })}
      </div>
    </div>
  );
};

export default Hotels;
