import React from "react";

const Vkicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M19.9989 0.5C8.95307 0.5 -0.00109863 9.45417 -0.00109863 20.5C-0.00109863 31.5458 8.95307 40.5 19.9989 40.5C31.0447 40.5 39.9989 31.5458 39.9989 20.5C39.9989 9.45417 31.0447 0.5 19.9989 0.5ZM27.6906 23.0646C27.6906 23.0646 29.4593 24.8104 29.8947 25.6208C29.9072 25.6375 29.9135 25.6542 29.9177 25.6625C30.0947 25.9604 30.1364 26.1917 30.0489 26.3646C29.9031 26.6521 29.4031 26.7938 29.2322 26.8063H26.1072C25.8906 26.8063 25.4364 26.75 24.8864 26.3708C24.4635 26.075 24.0468 25.5896 23.6406 25.1167C23.0343 24.4125 22.5093 23.8042 21.9802 23.8042C21.9129 23.8041 21.8461 23.8146 21.7822 23.8354C21.3822 23.9646 20.8697 24.5354 20.8697 26.0562C20.8697 26.5312 20.4947 26.8042 20.2302 26.8042H18.7989C18.3114 26.8042 15.7718 26.6333 13.5218 24.2604C10.7677 21.3542 8.28848 15.525 8.26765 15.4708C8.1114 15.0937 8.43432 14.8917 8.7864 14.8917H11.9427C12.3635 14.8917 12.501 15.1479 12.5968 15.375C12.7093 15.6396 13.1218 16.6917 13.7989 17.875C14.8968 19.8042 15.5697 20.5875 16.1093 20.5875C16.2105 20.5863 16.3099 20.5606 16.3989 20.5125C17.1031 20.1208 16.9718 17.6104 16.9406 17.0896C16.9406 16.9917 16.9385 15.9667 16.5781 15.475C16.3197 15.1187 15.8802 14.9833 15.6135 14.9333C15.7214 14.7844 15.8636 14.6636 16.0281 14.5812C16.5114 14.3396 17.3822 14.3042 18.2468 14.3042H18.7281C19.6656 14.3167 19.9072 14.3771 20.2468 14.4625C20.9343 14.6271 20.9489 15.0708 20.8885 16.5896C20.8697 17.0208 20.851 17.5083 20.851 18.0833C20.851 18.2083 20.8447 18.3417 20.8447 18.4833C20.8239 19.2562 20.7989 20.1333 21.3447 20.4937C21.4159 20.5384 21.4982 20.5622 21.5822 20.5625C21.7718 20.5625 22.3427 20.5625 23.8885 17.9104C24.3652 17.0568 24.7795 16.1697 25.1281 15.2562C25.1593 15.2021 25.251 15.0354 25.3593 14.9708C25.4393 14.9301 25.5279 14.9093 25.6177 14.9104H29.3281C29.7322 14.9104 30.0093 14.9708 30.0614 15.1271C30.1531 15.375 30.0447 16.1312 28.351 18.425L27.5947 19.4229C26.0593 21.4354 26.0593 21.5375 27.6906 23.0646Z"
        fill="#278084"
      />
    </svg>
  );
};

export default Vkicon;
