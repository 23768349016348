import React, { useEffect, useState } from "react";
import "./Homepage.scss";
import HeroSection from "../../components/heroSection/HeroSection";
import CountryTourSection from "../../components/countryToursSection/CountryTourSection";
import ExcursionSection from "../../components/excursionSection/ExcursionSection";
import CruiseSection from "../../components/cruiseSection/CruiseSection";
import ServicesSection from "../../components/servicesSection/ServicesSection";
import $api from "../../http";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const [homeData, setHomeData] = useState();
  const [topCountries, setTopCountries] = useState([]);
  const [homeExcursions, setHomeExcursions] = useState([]);
  const [homeCruises, setHomeCruises] = useState([]);
  const [heroBackground, setHeroBackground] = useState("");
  async function getAll() {
    await $api
      .get(`/home`)
      .then((res) => {
        setHomeData(res.data);
        setTopCountries(res.data.topCounty);
        setHomeExcursions(res.data.excursion);
        setHomeCruises(res.data.cruise);
        setHeroBackground(res.data.backgroundName);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getAll();
  }, []);
  return (
    <div className="homepage">
      <Helmet>
        <title>Home Page - Lada Travell</title>
        <meta
          name="description"
          content="Welcome to the home page of Lada Travell"
        />
      </Helmet>
      <HeroSection heroBackground={heroBackground} />
      <CountryTourSection topCountries={topCountries} homeData={homeData} />
      <ExcursionSection homeExcursions={homeExcursions} homeData={homeData} />
      <ServicesSection servicesData={homeData} homeData={homeData} />
      <CruiseSection homeCruises={homeCruises} homeData={homeData} />
    </div>
  );
};

export default Homepage;
