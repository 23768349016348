import React, { useEffect, useState } from "react";
import "./HolidaySeasonality.scss";
import seasoneBG from "../../assets/image/seasone.png";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import axios from "axios";
import SeasonesTable from "../../components/seasonesTable/SeasonesTable";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const HolidaySeasonality = () => {
  const data = {
    bg: seasoneBG,
    titile: "СЕЗОННОСТЬ ОТДЫХА",
    desc: [
      "Довольно часто перед туристом встаёт вопрос: Куда поехать отдыхать?. Приведённые ниже таблицы призваны облегчить выбор направления для отдыха.",
      "Также приводится удобная градация по цветам в зависимости от сезонности: пляжный сезон, экскурсионный сезон и горнолыжный сезон. Наиболее популярные страны для отдыха выделены жирным шрифтом.",
      "В традиционно пляжных странах неподходящим временем для путешествий (белые ячейки) в большинстве своём является либо сезон дождей, либо слишком сильная жара.",
    ],
  };
  const [europe, setEurope] = useState(null);
  const [asia, setAsia] = useState(null);
  const [africa, setAfrica] = useState(null);
  const [america, setAmerica] = useState(null);
  const [australia, setAustralia] = useState(null);
  const [islands, setIslands] = useState(null);

  async function getSeasonesList() {
    await axios
      .get("/json/europeSeasones.json")
      .then((res) => {
        setEurope(res.data);
      })
      .catch((err) => console.error(err));
    await axios
      .get("/json/asiaSeasones.json")
      .then((res) => {
        setAsia(res.data);
      })
      .catch((err) => console.error(err));
    await axios
      .get("/json/africaSeasones.json")
      .then((res) => {
        setAfrica(res.data);
      })
      .catch((err) => console.error(err));
    await axios
      .get("/json/americaSeasones.json")
      .then((res) => {
        setAmerica(res.data);
      })
      .catch((err) => console.error(err));
    await axios
      .get("/json/australiaSeasones.json")
      .then((res) => {
        setAustralia(res.data);
      })
      .catch((err) => console.error(err));
    await axios
      .get("/json/islandsSeasones.json")
      .then((res) => {
        setIslands(res.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getSeasonesList();
  }, []);

  return (
    <div className="holiday_seasonality">
      <Helmet>
        <title>{data?.titile}</title>
        <meta
          name="description"
          content="Довольно часто перед туристом встаёт вопрос: Куда поехать отдыхать?. Приведённые ниже таблицы призваны облегчить выбор направления для отдыха. Также приводится удобная градация по цветам в зависимости от сезонности: пляжный сезон, экскурсионный сезон и горнолыжный сезон. Наиболее популярные страны для отдыха выделены жирным шрифтом. В традиционно пляжных странах неподходящим временем для путешествий (белые ячейки) в большинстве своём является либо сезон дождей, либо слишком сильная жара. "
        />
      </Helmet>
      <div className="hero_div">
        <HeroBgName name={data?.titile} background={data?.bg} />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{data?.titile}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={data?.desc} />
      </div>
      <div className="holiday_seasonality_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
      <div className="holiday_seasonality_seasones">
        <div className="holiday_seasonality_seasones_title">
          <TitleDesc title={"ТАБЛИЦА СЕЗОНОВ ДЛЯ ОТДЫХА ПО СТРАНАМ"} />
        </div>
        <div className="holiday_seasonality_seasones_indicators">
          <div className="holiday_seasonality_seasones_indicators_item yellow">
            <p>Пляжный сезон</p>
          </div>
          <div className="holiday_seasonality_seasones_indicators_item green">
            <p>Экскурсионный сезон</p>
          </div>
          <div className="holiday_seasonality_seasones_indicators_item blue">
            <p>Горнолыжный сезон</p>
          </div>
        </div>
        <SeasonesTable title={"ЕВРОПА"} data={europe} />
        <SeasonesTable title={"АЗИЯ"} data={asia} />
        <SeasonesTable title={"АФРИКА"} data={africa} />
        <SeasonesTable title={"АМЕРИКА"} data={america} />
        <SeasonesTable title={"АВСТРАЛИЯ И ОКЕАНИЯ"} data={australia} />
        <SeasonesTable title={"ОСТРОВА"} data={islands} />
      </div>
    </div>
  );
};

export default HolidaySeasonality;
