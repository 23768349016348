import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "./AccordionSec.scss";
import PlusIcon from "../../command/icons/PlusIcon";
import MinusIcon from "../../command/icons/MinusIcon";
const AccordionSec = ({ data }) => {
  return (
    <div className="accardion_section container">
      <Accordion allowZeroExpanded>
        {data?.map((item) => {
          return (
            <AccordionItem key={item?.id} uuid={item?.id}>
              <AccordionItemHeading>
                <AccordionItemState>
                  {({ expanded }) =>
                    expanded ? (
                      <AccordionItemButton className="accordion__button expanded">
                        <h3 className="accordion_header_title">
                          {item?.title}
                        </h3>
                        <MinusIcon />
                      </AccordionItemButton>
                    ) : (
                      <AccordionItemButton className="accordion__button collapsed">
                        <h3 className="accordion_header_title">
                          {item?.title}
                        </h3>
                        <PlusIcon />
                      </AccordionItemButton>
                    )
                  }
                </AccordionItemState>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item?.info ? (
                  <>
                    {item?.info?.map((elem, index) => {
                      return (
                        <div key={index} className="accordion_paragraph">
                          <p>{elem?.title ? elem?.title : elem?.text}</p>
                          {elem?.sublist ? (
                            <ul>
                              {elem?.sublist?.map((li, idx) => {
                                return li ? (
                                  <li key={idx}>
                                    <span> {li ? li : null}</span>
                                  </li>
                                ) : null;
                              })}
                            </ul>
                          ) : null}
                        </div>
                      );
                    })}
                  </>
                ) : item?.accordionList ? (
                  <>
                    {item?.accordionList?.map((elem, index) => {
                      return (
                        <div key={index} className="accordion_paragraph">
                          <p>{elem?.title || elem?.text}</p>
                          {elem?.sublist ? (
                            <ul>
                              {elem?.sublist?.map((li, idx) => {
                                return li?.sub ? (
                                  <li key={idx}>
                                    <span>{li?.sub || ""}</span>
                                  </li>
                                ) : null;
                              })}
                            </ul>
                          ) : null}
                        </div>
                      );
                    })}
                  </>
                ) : item?.titleList ? (
                  <>
                    {item?.titleList?.map((elem, index) => {
                      return (
                        <div key={index} className="accordion_paragraph">
                          <p>{elem?.title || elem?.text}</p>
                          {elem?.sub.length ? (
                            <ul>
                              {elem?.sub?.map((li, idx) => {
                                return li?.title ? (
                                  <li key={idx}>
                                    <span>{li?.title || ""}</span>
                                  </li>
                                ) : null;
                              })}
                            </ul>
                          ) : null}
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

export default AccordionSec;
