import React from "react";
import "./CountryTourCard.scss";
const CountryTourCard = ({ title, count, src, id, onNavigation }) => {
  const navigate = () => {
    onNavigation(id);
  };

  return (
    <div className="country_tour_card" onClick={navigate}>
      <div className="image_section">
        <div className="transparent_div"></div>
        {src.includes(".jpg") ||
        src.includes(".webp") ||
        src.includes(".jpeg") ||
        src.includes(".gif") ||
        src.includes(".png") ? (
          <img src={src} alt="country" />
        ) : (
          <video src={src} loop muted autoPlay playsInline></video>
        )}
      </div>
      <div className="info_section">
        {/* <h1>{title}</h1> */}
        <h1>
          {title?.length > 21 ? (
            <>{title.substring(22, "") + "..."}</>
          ) : (
            <>{title}</>
          )}
        </h1>
        <p>Количество туров {count}</p>
      </div>
    </div>
  );
};

export default CountryTourCard;
