import React, { useEffect, useState } from "react";
import "./AllCountries.scss";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import CountryTourCard from "../../components/countryTourCard/CountryTourCard";
import { NavLink, useNavigate } from "react-router-dom";
import $api from "../../http";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";

const AllCountries = () => {
  const [allCountries, setAllCountries] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const navigation = useNavigate();

  const onNavigation = (id) => {
    navigation(`/country/${id}`);
  };

  async function getAllCountries() {
    await $api
      .post(`/countrie`, { currentPage: pageCount, pageSize: itemsPerPage })
      .then((response) => {
        const { newdata, totalPages: total } = response.data;
        setAllCountries([...newdata]);
        setItemOffset(total);
      });
  }

  useEffect(() => {
    getAllCountries();
  }, [pageCount, itemsPerPage]);

  const handlePageClick = (newPage) => {
    setPageCount(newPage.selected + 1);
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [allCountries]);

  return (
    <div className="allcountry_page">
      <Helmet>
        <title>Все Страны</title>
        <meta
          name="description"
          content="Наиболее посещаемые курорты, города и пункты назначения на турах по Армении,которые включены в наши туристические маршруты."
        />
      </Helmet>
      <div className="container_allCountry">
        <TitleDesc
          title={"Все Страны"}
          //   description={
          //     "Наиболее посещаемые курорты, города и пункты назначения на турах по Армении,которые включены в наши туристические маршруты."
          //   }
        />
        <div className="card_section">
          {allCountries.map((item) => {
            return item?.active ? (
              <CountryTourCard
                key={item.id}
                title={item.name}
                count={item.count}
                src={item.mainBackground}
                id={item.id}
                onNavigation={onNavigation}
              />
            ) : null;
          })}
        </div>
        <div className="pagination-section">
          <ReactPaginate
            breakLabel="..."
            nextLabel="След >"
            onPageChange={handlePageClick}
            pageCount={itemOffset}
            previousLabel="< Пред"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
          />
        </div>
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">ВСЕ СТРАНЫ</NavLink>
      </div>
    </div>
  );
};

export default AllCountries;
