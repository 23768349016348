import React from "react";
import "./Input.scss";
const Input = ({
  variant,
  value,
  placeholder,
  onChange,
  type,
  inputmode = "text",
}) => {
  return (
    <div
      className={` custom_input  ${variant === "white" ? "white_bg" : ""}  `}
    >
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        inputmode={inputmode}
      />
    </div>
  );
};

export default Input;
