import React, { useEffect, useState } from "react";
import "./ExcursionsInArmenia.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import { NavLink, useParams } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const ExcursionsInArmenia = () => {
  const [currentArmeniaExcursion, setCurrentArmeniaExcursion] = useState();
  const { id } = useParams();

  const [countryTours, setCountryTours] = useState([]);

  async function getCurrentArmeniaExcursionData() {
    await $api
      .post(`/armenia/get-current-excursion`, { id: id })
      .then((response) => {
        console.log(response.data, "eqskursiahay")
        setCurrentArmeniaExcursion(response.data);
      });
    await $api.get(`/armenia/get-authors-tours-data`).then((response) => {
      setCountryTours(
        response.data.map((item) => {
          return {
            name: item.title,
          };
        })
      );
    });
  }

  useEffect(() => {
    getCurrentArmeniaExcursionData();
  }, []);
  return (
    <div className="excursion_in_armenia">
      <Helmet>
        <title>{currentArmeniaExcursion?.title}</title>
        <meta
          name="description"
          content={currentArmeniaExcursion?.description}
        />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={currentArmeniaExcursion?.title}
          backgrounds={currentArmeniaExcursion?.armCurrentBackgrounds}
          slider={
            currentArmeniaExcursion?.armCurrentBackgrounds?.length > 1
              ? true
              : false
          }
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink to="/armenia" className="home_nav">
            Армения
          </NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">
            {currentArmeniaExcursion?.title}
          </NavLink>
        </div>
      </div>
      <div className="title_section">
        <TitleDesc title={currentArmeniaExcursion?.programmTitle} />
      </div>
      <div className="excursion_programm">
        <ul>
          {currentArmeniaExcursion?.armCurrentListSubs?.map((item, index) => {
            return item?.text ? (
              <li key={index}>
                {" "}
                <span> {item.text} </span>
              </li>
            ) : null;
          })}
        </ul>
      </div>
      <div className="description_section">
        <TitleDesc description={currentArmeniaExcursion?.description} />
      </div>
      <div className="excursions containerXL">
        {currentArmeniaExcursion?.armCurrentCardItems?.map((item) => {
          return (
            <FullWidthCard
              key={item?.id}
              data={item}
              hideButton={true}
              size={"small"}
            />
          );
        })}
      </div>
      <div className="about_price">
        <div className="left_section">
          <div className="price_title_section">
            <h1>{currentArmeniaExcursion?.whatIncludes}</h1>
          </div>
          <ul>
            {currentArmeniaExcursion?.armCurrentwhatIncludes?.map(
              (item, index) => {
                return item?.text ? (
                  <li key={index}>
                    {" "}
                    <span> {item?.text}</span>
                  </li>
                ) : null;
              }
            )}
          </ul>
        </div>
        <div className="right_section">
          <div className="price_title_section">
            <h1>{currentArmeniaExcursion?.whatNotIncludes}</h1>
          </div>
          <ul>
            {currentArmeniaExcursion?.armCurrentwhatIsNotIncludes?.map(
              (item, index) => {
                return item?.text ? (
                  <li key={index}>
                    {" "}
                    <span> {item?.text} </span>
                  </li>
                ) : null;
              }
            )}
          </ul>
        </div>
      </div>
      <div className="form_section">
        <SelectTourForm
          variant={"white"}
          selects={true}
          splitLastOne={true}
          currentCountry={{ name: "АРМЕНИЯ" }}
          countryTours={countryTours}
        />
      </div>
    </div>
  );
};

export default ExcursionsInArmenia;
