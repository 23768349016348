import React, { useEffect, useState } from "react";
import "./OtherToursInArmenia.scss";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import ExcursionCard from "../../components/excursionCard/ExcursionCard";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const OtherToursInArmenia = () => {
  const [allArmeniaTour, setAllArmeniaTour] = useState();
  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  async function getAllArmeniaTourData() {
    await $api.get(`armenia/get-authors-tours-data`).then((response) => {
      setAllArmeniaTour(response.data);
    });
  }

  useEffect(() => {
    getAllArmeniaTourData();
  }, []);

  const tourNavigation = (id) => {
    navigation(`/armenia/tours-in-armenia/${id}`);
  };
  return (
    <div className="global_page">
      <Helmet>
        <title>ВСЕ АВТОРСКИЕ ТУРЫ</title>
        <meta name="description" content="СКАЗОЧНЫЙ ОТДЫХ В АРМЕНИИ" />
      </Helmet>
      <div className="other_tours_in_armenia">
        <TitleDesc title={"ВСЕ АВТОРСКИЕ ТУРЫ"} />
        <div className="card_section container">
          {allArmeniaTour?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={tourNavigation}
              />
            );
          })}
        </div>
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink to="/armenia" className="home_nav">
          Армения
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">ВСЕ АВТОРСКИЕ ТУРЫ</NavLink>
      </div>
    </div>
  );
};

export default OtherToursInArmenia;
