import React, { useEffect, useState } from "react";
import "./AboutArmenia.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import ExcursionCard from "../../components/excursionCard/ExcursionCard";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import Button from "../../command/button/Button";
import ServicesCard from "../../components/servicesCard/ServicesCard";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const AboutArmenia = () => {
  const [aboutArmenia, setAboutArmenia] = useState();
  const navigation = useNavigate();

  const excursionNavigation = (id) => {
    navigation(`/armenia/excursions-in-armenia/${id}`);
  };

  const tourNavigation = (id) => {
    navigation(`/armenia/tours-in-armenia/${id}`);
  };
  const handleOtherExcursionsInArmenia = () => {
    navigation(`/other-excursions-in-armenia`);
  };
  const handleOtherToursInArmenia = () => {
    navigation(`/other-tours-in-armenia`);
  };

  async function getAboutArmeniaData() {
    await $api.get(`/armenia`).then((response) => {
      setAboutArmenia(response.data[0]);
    });
  }

  useEffect(() => {
    getAboutArmeniaData();
  }, []);

  return (
    <div className="armenia_page">
      <Helmet>
        <title>{aboutArmenia?.title}</title>
        <meta name="description" content={aboutArmenia?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={aboutArmenia?.title}
          backgrounds={aboutArmenia?.ArmeniaBackgrounds}
          slider={aboutArmenia?.ArmeniaBackgrounds?.length > 1 ? true : false}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{aboutArmenia?.title}</NavLink>
        </div>
      </div>
      <div className="container">
        <div className="desc_section">
          <DescSection text={aboutArmenia?.authorsExcursionDesc} />
        </div>
        <TitleDesc title={aboutArmenia?.authorsExcursionTitle} />
        <div className="cruise_card_section">
          {aboutArmenia?.ArmenianExcursions?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                onNavigation={excursionNavigation}
              />
            );
          })}
        </div>
        <div className="button_section">
          <Button
            variant={"primary"}
            name="ДРУГИЕ ЭКСКУРСИИ"
            size={"large"}
            onClick={handleOtherExcursionsInArmenia}
          />
        </div>
        <div className="description_section">
          <DescSection text={aboutArmenia?.authorsToursDesc} />
        </div>
        <TitleDesc title={aboutArmenia?.authorsToursTitle} />
        <div className="cruise_card_section">
          {aboutArmenia?.ArmenianTours.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                onNavigation={tourNavigation}
              />
            );
          })}
        </div>
        <div className="button_section">
          <Button
            variant={"primary"}
            name="ВСЕ АВТОРСКИЕ ТУРЫ"
            size={"large"}
            onClick={handleOtherToursInArmenia}
          />
        </div>
        <div className="description_section">
          <DescSection text={aboutArmenia?.feelArmeniaDesc} />
        </div>
        <TitleDesc title={aboutArmenia?.feelArmeniaTitle} />
        <div className="popular_directions_card_section container">
          {aboutArmenia?.FeelArmenia.map((item, index) => {
            return (
              <ServicesCard
                title={item.title}
                src={item.src}
                backgroundName={item.backgroundName}
                key={index}
              />
            );
          })}
        </div>
        <div className="description_section">
          <DescSection text={aboutArmenia?.description} />
        </div>
      </div>
    </div>
  );
};

export default AboutArmenia;
