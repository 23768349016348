import React from "react";
import "./Footer.scss";
import WhatsappIcon from "../../command/icons/WhatsappIcon";
import TelegramIcon from "../../command/icons/TelegramIcon";
import Vkicon from "../../command/icons/Vkicon";
import FacebookIcon from "../../command/icons/FacebookIcon";
import InstagramIcon from "../../command/icons/InstagramIcon";
import Mainlogo from "../../command/icons/Mainlogo";
const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <div className="left_section">
        <div onClick={scrollTop}>
          <Mainlogo />
        </div>
        <h2>
          <a href="tel:+37477533561">+37477533561</a>
        </h2>
      </div>
      <div className="middle_section">
        <p>ПУТЕШЕСТВУЙТЕ ВМЕСТЕ С LADA TRAVEL: НАМ ДОВЕРЯЮТ, С НАМИ НАДЕЖНО</p>
      </div>
      <div className="right_section">
        <a href="https://wa.me/37477533561">
          <WhatsappIcon />
        </a>
        <a href="https://t.me/lada_travel_armenia">
          <TelegramIcon />
        </a>
        <a href="https://vk.com/tur_otpusk_ladatravel">
          <Vkicon />
        </a>
        <a href="https://www.facebook.com/ladatravell?mibextid=LQQJ4d">
          <FacebookIcon />
        </a>
        <a href="https://www.instagram.com/ladatravell?igsh=MTBjOHMwZWVtOGFhcQ%3D%3D&utm_source=qr">
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
};

export default Footer;
