import React, { useEffect, useState } from "react";
import "./SkiHoliday.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import SkiHolidayResorts from "../../components/skiHolodayResorts/SkiHolidayResorts";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const SkiHoliday = () => {
  const [skiHolidayData, setSkiHolidayData] = useState(null);
  const [skiCountries, setSkiCountries] = useState();

  async function getSkiHolidayData() {
    await $api.get(`/ski-holiday`).then((response) => {
      setSkiHolidayData(response.data);
    });
  }

  async function getSkiCountries() {
    await $api.get(`/ski-holiday/get-resort-countres`).then((response) => {
      setSkiCountries(response.data);
    });
  }

  useEffect(() => {
    getSkiHolidayData();
    getSkiCountries();
  }, []);
  return (
    <div className="resort_main">
      <Helmet>
        <title>{skiHolidayData?.skiHoliday?.title}</title>
        <meta
          name="description"
          content={skiHolidayData?.skiHoliday?.description}
        />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={skiHolidayData?.skiHoliday?.title}
          background={skiHolidayData?.skiHoliday?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">
            {skiHolidayData?.skiHoliday?.title}
          </NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={skiHolidayData?.skiHoliday?.description} />
      </div>
      <div className="resort_sng_section">
        <TitleDesc
          title={skiHolidayData?.skiHoliday?.resortsCISTitle}
          description={skiHolidayData?.skiHoliday?.resortsCISDescription}
        />
        <div className="resort_sng_section_list containerXL">
          {skiHolidayData?.resortsCIS?.map((item) => {
            return <FullWidthCard key={item.id} data={item} hideButton />;
          })}
        </div>
      </div>
      <div className="resort_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
      <div className="resort_europe_section">
        <TitleDesc
          title={skiHolidayData?.skiHoliday?.resortsEuropeTitle}
          description={skiHolidayData?.skiHoliday?.resortsEuropeDescription}
        />

        <div className="resort_europe_section_list containerXL">
          {skiHolidayData?.resortsEVR?.map((item) => {
            return <FullWidthCard key={item.id} data={item} hideButton />;
          })}
        </div>
      </div>
      <div className="skiHoliday_resorts">
        <div className="skiHoliday_resorts_header">
          <TitleDesc title={"Каталог горнолыжных курортов"} />
        </div>
        <div className="skiHoliday_resorts_content">
          <SkiHolidayResorts count={true} skiCountries={skiCountries} />
        </div>
      </div>
    </div>
  );
};

export default SkiHoliday;
