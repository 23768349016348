import React from "react";
import "./ServicesCard.scss";
const ServicesCard = ({ title, src, id, onNavigation, backgroundName }) => {
  const navigate = () => {
    onNavigation(id);
  };
  const media = src || backgroundName;

  function cardMedia() {
    if (
      media.includes(".jpg") ||
      media.includes(".webp") ||
      media.includes(".jpeg") ||
      media.includes(".gif") ||
      media.includes(".png")
    ) {
      return <img src={media} alt="country" />;
    } else {
      return <video src={media} loop muted autoPlay playsInline></video>;
    }
  }
  return (
    <div className="services_card" onClick={navigate}>
      <div className="image_section">
        {cardMedia()}
        <div className="transparent_div"></div>
      </div>
      <div className="info_section">
        <h1>
          {title?.length > 70 ? (
            <>{title.substring(70, "") + "..."}</>
          ) : (
            <>{title}</>
          )}
        </h1>
      </div>
    </div>
  );
};

export default ServicesCard;
