import React, { useEffect, useState } from "react";
import "./AboutUs.scss";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState([]);

  async function getAboutUs() {
    await $api.get(`info/about-us`).then((response) => {
      setAboutUs(response.data);
    });
  }

  useEffect(() => {
    getAboutUs();
  }, []);

  return (
    <div
      className="about_us_page"
      style={{ backgroundImage: `url(${aboutUs.backgroundName})` }}
    >
      <Helmet>
        <title>О Компании</title>
        <meta name="description" content={aboutUs.description} />
      </Helmet>
      <div className="about_us">
        <div className="lada_image">
          <img src={aboutUs.profileImages} alt="lada" />
        </div>
        <div className="description">
          <p>{aboutUs.description}</p>
        </div>
      </div>
      <div className="breadcrumbs">
        <NavLink to="/" className="home_nav">
          Главная
        </NavLink>
        <ArrowRightIcon />
        <NavLink className="last-link">О Компании</NavLink>
      </div>
    </div>
  );
};

export default AboutUs;
