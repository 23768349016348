import React, { useState } from "react";
import "./Select.scss";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import ChevronDownIcon from "../icons/ChevronDownIcon";

const Select = ({
  variant,
  title,
  defaultValue,
  onValueChange,
  options,
  selectedValue,
  setSelectedValue,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  const handleSelect = (option) => {
    onValueChange(option);
    setIsOpen(false);
  };

  return (
    <div
      className={` dropdownContainer  ${
        variant === "white" ? "white_bg" : ""
      }  `}
    >
      <div className="dropdownBody" ref={ref}>
        <div
          className={`dropdownHeader ${
            !isOpen ? "dropdownClose" : "dropdownOpen"
          }`}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <span className={!selectedValue ? "default" : ""}>
            {!selectedValue ? defaultValue : selectedValue.name}
          </span>
          <ChevronDownIcon width="18px" height="18px" fill="" />
        </div>
        {isOpen && (
          <div
            className={` dropdownOptions ${
              !isOpen ? "dropdownOptionsClose" : "dropdownOptionsOpen"
            }`}
          >
            {options?.map(
              (option, index) =>
                option?.name && (
                  <div
                    key={index}
                    className={`dropdownOption ${
                      option?.name === selectedValue?.name ? "active" : ""
                    }`}
                    onClick={() => handleSelect(option)}
                  >
                    {option?.name}
                  </div>
                )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
