import React from "react";

const TelegramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="41"
      viewBox="0 0 48 41"
      fill="none"
    >
      <path
        d="M45.332 0.578503C44.1883 0.87851 1.41861 17.3789 0.968602 17.6976C-0.212672 18.5414 -0.231423 19.5352 0.931102 20.1164C1.30611 20.3039 4.09992 21.2414 7.13748 22.179L12.6688 23.8852L25.5129 15.8413C32.5818 11.4162 38.5444 7.77866 38.7507 7.75991C39.3882 7.68491 39.5944 7.74116 39.5944 8.00366C39.5944 8.15366 34.9256 12.4475 29.2254 17.5664L18.8565 26.8853L18.519 31.7042C18.3502 34.3667 18.1627 36.8605 18.1065 37.2355C17.994 37.9293 18.0127 37.948 18.5752 37.948C19.044 37.948 19.5127 37.6293 20.994 36.2793C21.9878 35.3605 23.2441 34.1792 23.7503 33.6355C24.2753 33.1104 24.9129 32.5104 25.1941 32.3042L25.7004 31.9479L30.988 35.8105C36.8756 40.1231 37.1569 40.3106 38.0944 40.4793C38.9007 40.6106 39.8194 40.1043 40.2319 39.2981C40.5319 38.7168 47.8071 4.94735 47.9758 3.33481C48.1821 1.42227 46.9821 0.165997 45.332 0.578503Z"
        fill="#278084"
      />
    </svg>
  );
};

export default TelegramIcon;
