import React from "react";
import "./ServicesSection.scss";
import ServicesCard from "../servicesCard/ServicesCard";
import TitleDesc from "../TitleDesc/TitleDesc";
import { useNavigate } from "react-router-dom";
const ServicesSection = ({ servicesData }) => {
  const navigation = useNavigate();

  const onNavigation = (id) => {
    navigation(`/services/${id}`);
  };
  return (
    <div className="popular_directions_section container">
      <TitleDesc
        title={servicesData?.titleOfTheThirdSection}
        description={servicesData?.descriptionOfTheThirdSection}
      />
      <div className="popular_directions_card_section container">
        {servicesData?.services.map((item) => {
          return (
            <ServicesCard
              title={item.name}
              src={item.images}
              key={item.id}
              id={item.id}
              onNavigation={onNavigation}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ServicesSection;
