import React from "react";
import "./CountryTourSection.scss";
import CountryTourCard from "../countryTourCard/CountryTourCard";
import Button from "../../command/button/Button";
import SelectTourForm from "../selectTourForm/SelectTourForm";
import { useNavigate } from "react-router-dom";
import TitleDesc from "../TitleDesc/TitleDesc";
const CountryTourSection = ({ topCountries, homeData }) => {
  const navigation = useNavigate();

  const onNavigation = (id) => {
    navigation(`/country/${id}`);
  };
  const handleAllCountries = () => {
    navigation(`/all-countries`);
  };
  const handleAuthorExcursions = () => {
    navigation(`/author-excursions`);
  };

  return (
    <div className="country_tour_section container">
      <div className="hot_tours_button">
        <button onClick={handleAuthorExcursions}>
          Авторские экскурсии по миру
        </button>
      </div>
      <TitleDesc
        title={homeData?.titleOfTheFirstSection}
        description={homeData?.descriptionOfTheFirstSection}
      />
      <div className="card_section">
        {topCountries.map((item) => {
          return item?.active ? (
            <CountryTourCard
              key={item.id}
              title={item.name}
              count={item.count}
              src={item.mainBackground}
              id={item.id}
              onNavigation={onNavigation}
            />
          ) : null;
        })}
      </div>
      <div className="button_section">
        <Button
          variant={"primary"}
          name="Все страны"
          size={"large"}
          onClick={handleAllCountries}
        />
      </div>
      <div className="choose_toure_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
    </div>
  );
};

export default CountryTourSection;
