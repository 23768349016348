import React, { useEffect, useRef, useState } from "react";
import "./Country.scss";
import { NavLink, useLocation, useParams } from "react-router-dom";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import CountryGeo from "../../components/countryGeo/CountryGeo";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import SmallVerticalCards from "../../components/smallVerticalCards/SmallVerticalCards";
import MoreInfoModal from "../../components/moreInfoModal/MoreInfoModal";
import AccordionSec from "../../components/accordionSec/AccordionSec";
import $api from "../../http";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const Country = () => {
  const location = useLocation();
  const [selectedTourData, setSelectedTourData] = useState(null);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const refForm = useRef(null);
  const [currentCountry, setCurrentCountry] = useState({});
  const [countryTours, setCountryTours] = useState([]);
  const [ddd, setDdd] = useState([]);
  const handleCardClick = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const onSeletcTur = (turName) => {
    setSelectedTourData(turName);
  };

  async function getCurrentCountry() {
    await $api.post(`countrie/get-countrie-item`, { id }).then((response) => {
      setCurrentCountry(response.data);
      setDdd(response.data.countryItemInfo);
      const tempArr = [];
      response.data.countryItemInfo.forEach((item) => {
        if (item.dataValues.top) {
          tempArr.push({
            name: item.dataValues.title,
          });
        }
      });
      setCountryTours(tempArr);
    });
  }

  useEffect(() => {
    getCurrentCountry();
  }, [location.pathname]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);
  return (
    <div className="country_details_section">
      <Helmet>
        <title>{currentCountry?.countrie?.name}</title>
        <meta
          name="description"
          content={currentCountry?.countryItem?.description}
        />
      </Helmet>
      <div className="hero_sec">
        <HeroBgName
          name={currentCountry?.countrie?.name}
          backgrounds={currentCountry?.mainPicture}
          slider={currentCountry?.mainPicture?.length > 1 ? true : false}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink to="/all-countries">Страны</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">
            {currentCountry?.countrie?.name}
          </NavLink>
        </div>
      </div>
      <TitleDesc
        title={currentCountry?.countryItem?.descriptionTitle}
        description={currentCountry?.countryItem?.description}
      />
      <CountryGeo
        image={currentCountry?.countryItem?.geographyAndClimateMap}
        geoTitle={currentCountry?.countryItem?.geographyAndClimateTitle}
        geodescription={
          currentCountry?.countryItem?.geographyAndClimateDescriptio
        }
      />
      <div className="country_services_section"></div>
      <div className="choose_toure_form" ref={refForm}>
        <SelectTourForm
          variant={"white"}
          selects={true}
          splitLastOne={true}
          currentCountry={currentCountry}
          countryTours={countryTours}
          selectedTourDataProps={selectedTourData}
        />
      </div>
      <div className="favorite_cities_section">
        <div className="favorite_cities_section_header">
          <h3>{currentCountry?.countryItem?.whereToRelaxIn}</h3>
        </div>
        <div className="favorite_cities_section_list containerXL">
          {ddd.map((item, index) => {
            return item?.dataValues?.top && item?.dataValues?.active ? (
              <FullWidthCard
                key={index}
                data={item?.dataValues}
                size={"small"}
                setSelectedTour={setSelectedTour}
                selectedTour={selectedTour}
                formRef={refForm}
                onSeletcTur={onSeletcTur}
              />
            ) : null;
          })}
        </div>
      </div>
      <div className="secondary_cities_section">
        <div className="secondary_cities_section_header">
          <h3>{currentCountry?.countryItem?.otherResorts}</h3>
        </div>
        <div className="secondary_cities_section_list containerXL">
          {ddd.map((item, index) => {
            return !item?.dataValues?.top && item?.dataValues?.active ? (
              <SmallVerticalCards
                key={index}
                data={item?.dataValues}
                onClick={() => handleCardClick(item)}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            ) : null;
          })}
        </div>
        <div className="modal_div">
          {showModal ? (
            <MoreInfoModal setShowModal={setShowModal} data={modalData} />
          ) : null}
        </div>
      </div>
      <div className="accordion">
        <AccordionSec data={currentCountry?.newData?.countrieAccordionList} />
      </div>
    </div>
  );
};

export default Country;
