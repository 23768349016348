import React, { useEffect, useState } from "react";
import "./SelectTourForm.scss";
import Input from "../../command/input/Input";
import Select from "../../command/select/Select";
import Button from "../../command/button/Button";
import WhatsappIcon from "./../../command/icons/WhatsappIcon";
import TelegramIcon from "./../../command/icons/TelegramIcon";
import Vkicon from "../../command/icons/Vkicon";
import FacebookIcon from "./../../command/icons/FacebookIcon";
import InstagramIcon from "./../../command/icons/InstagramIcon";
import $api from "../../http";
import { toast } from "react-toastify";
const SelectTourForm = ({
  variant,
  selects,
  splitLastOne,
  currentCountry,
  countryTours,
  selectedTourDataProps,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(currentCountry);
  const [selectedTourData, setSelectedTourData] = useState(null);
  const [tour, setTour] = useState(countryTours);

  const chooseTourChange = (option) => {
    setSelectedTourData(option);
  };

  const chooseCountryChange = async (option) => {
    setSelectedCountry(option);
    setSelectedTourData(null);
    await $api
      .post(`/countrie/get-countrie-item`, { id: option.id })
      .then((response) => {
        setTour(
          response.data.countryItemInfo.map((item) => {
            if (item.dataValues.top) {
              return {
                name: item.dataValues.title,
              };
            }
          })
        );
      });
  };

  const [country, setCountry] = useState([]);
  async function getCountries() {
    await $api.post(`/countrie`).then((response) => {
      setCountry(response.data);
    });
  }

  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onChangeName = (event) => {
    let input = event.target.value;
    let regex = /^(?![\d ])(?:[a-zA-Z_ ]*|(?:[А-Яа-яЁё]*))$/;
    if (regex.test(input)) {
      setUserName(event.target.value);
    }
  };

  const onPhoneUmber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const notifyErr = () => toast.error("Заполните все поля!");
  const notifyApp = () => toast.success("Ваша заявка принята!");
  const onSubmit = async () => {
    if (
      selectedCountry?.name ||
      selectedCountry === undefined ||
      selectedCountry === null
    ) {
      if (
        !phoneNumber.trim() ||
        !userName.trim() ||
        !selectedCountry?.name ||
        !selectedTourData?.name
      ) {
        notifyErr();
      } else {
        await $api
          .post("/application-for-a-tour", {
            name: userName,
            phoneNumber: phoneNumber,
            country: selectedCountry.name,
            turName: selectedTourData.name,
          })
          .then((response) => {
            if (response.status === 200) {
              notifyApp();
            }
          });
        setUserName("");
        setPhoneNumber("");
        setSelectedCountry(null);
        setSelectedTourData(null);
      }
    }
    if (currentCountry?.countrie?.name) {
      if (
        !phoneNumber.trim() ||
        !userName.trim() ||
        !currentCountry?.countrie?.name ||
        !selectedTourData?.name
      ) {
        notifyErr();
      } else {
        await $api
          .post("/application-for-a-tour", {
            name: userName,
            phoneNumber: phoneNumber,
            country: currentCountry.countrie.name,
            turName: selectedTourData.name,
          })
          .then((response) => {
            if (response.status === 200) {
              notifyApp();
            }
          });
        setUserName("");
        setPhoneNumber("");
        setSelectedCountry(null);
        setSelectedTourData(null);
      }
    }
  };

  useEffect(() => {
    if (selectedTourDataProps) {
      setSelectedTourData({ name: selectedTourDataProps });
    }
    getCountries();
  }, [selectedTourDataProps]);

  return (
    <div
      className={` select_tour_section  ${
        variant === "white" ? "white_bg" : ""
      }  `}
    >
      <div className="title_section_form">
        <h1>ПОДОБРАТЬ ТУР</h1>
      </div>
      <div className="select_section">
        <Input
          variant={variant}
          value={userName}
          onChange={onChangeName}
          placeholder="Ваше имя*"
          type="text"
        />
        <Input
          variant={variant}
          value={phoneNumber}
          onChange={onPhoneUmber}
          placeholder="Ваш телефон*"
          type="number"
          inputmode="decimal"
        />
        {selects && (
          <>
            {!splitLastOne ? (
              <Select
                variant={variant}
                options={country}
                defaultValue="Страна/Город вылета*"
                onValueChange={chooseCountryChange}
                selectedValue={selectedCountry}
                setSelectedValue={setSelectedCountry}
              />
            ) : null}
            <Select
              variant={variant}
              options={countryTours || tour}
              defaultValue="Выбрать тур*"
              onValueChange={chooseTourChange}
              selectedValue={selectedTourData}
              setSelectedValue={chooseTourChange}
              disabled={!selectedCountry}
            />
          </>
        )}
      </div>
      <div className="text_section">
        <span>
          Нажимая, вы даете согласие на обработку персональных данных.
        </span>
      </div>
      <Button
        onClick={onSubmit}
        variant={"primary"}
        name="Подобрать тур"
        size={"large"}
      />
      <div className="social_networks_section">
        <div className="top_section">
          <a href="https://wa.me/79626693127">
            <WhatsappIcon />
          </a>
          <a href="https://t.me/lada_travel_armenia">
            <TelegramIcon />
          </a>
          <a href="https://vk.com/tur_otpusk_ladatravel">
            <Vkicon />
          </a>
        </div>
        <hr />
        <div className="bottom_section">
          <a href="https://www.facebook.com/ladatravell?mibextid=LQQJ4d">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/lada_travel_agency/">
            <InstagramIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SelectTourForm;
