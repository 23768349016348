import React, { useEffect, useState } from "react";
import "./HolidayWithKids.scss";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import AccordionSec from "../../components/accordionSec/AccordionSec";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const HolidayWithKids = () => {
  const [vacationWithChildren, setVacationWithChildren] = useState(null);
  async function getVacationWithChildrenData() {
    await $api.get(`/vacation-with-children`).then((response) => {
      setVacationWithChildren(response.data);
    });
  }

  useEffect(() => {
    getVacationWithChildrenData();
  }, []);

  return (
    <div className="holiday_with_kids">
      <Helmet>
        <title>{vacationWithChildren?.title}</title>
        <meta name="description" content={vacationWithChildren?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={vacationWithChildren?.title}
          background={vacationWithChildren?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{vacationWithChildren?.title}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={vacationWithChildren?.description} />
      </div>
      <div className="holiday_with_kids_seasones">
        <div className="holiday_with_kids_seasones_header">
          <TitleDesc title={vacationWithChildren?.whenIsTheBestTitle} />
        </div>
        <div className="holiday_with_kids_seasones_list containerXL">
          {vacationWithChildren?.whenIsTheBest?.map((item) => {
            return (
              <FullWidthCard
                key={item.id}
                data={item}
                variant={"small"}
                hideButton
              />
            );
          })}
        </div>
      </div>
      <div className="holiday_with_kids_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
      <AccordionSec data={vacationWithChildren?.whenIsTheBestInfoArray} />
    </div>
  );
};

export default HolidayWithKids;
