import React, { useEffect, useState } from "react";
import "./TicketSearch.scss";
import HeroBgName from "./../../components/heroBgName/HeroBgName";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import DescSection from "./../../components/descSection/DescSection";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const TicketSearch = () => {
  const [ticketSearchData, setTicketSearchData] = useState([]);
  async function getTicketSearchData() {
    await $api.get(`/air-ticket-search`).then((response) => {
      setTicketSearchData(response.data);
    });
  }

  useEffect(() => {
    getTicketSearchData();
  }, []);
  return (
    <div className="ticket_search_page">
      <Helmet>
        <title>{ticketSearchData?.title}</title>
        <meta name="description" content={ticketSearchData?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          background={ticketSearchData?.backgroundName}
          name={ticketSearchData?.title}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{ticketSearchData?.title}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={ticketSearchData?.description} />
      </div>
      <div className="choose_toure_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
    </div>
  );
};

export default TicketSearch;
