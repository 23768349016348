import React from "react";
import "./ExcursionCard.scss";
import Button from "../../command/button/Button";
const ExcursionCard = ({ data, onNavigation, onClick }) => {
  const media = data?.src || data?.backgroundName;

  function cardMedia() {
    if (
      media.includes(".jpg") ||
      media.includes(".webp") ||
      media.includes(".jpeg") ||
      media.includes(".gif") ||
      media.includes(".png")
    ) {
      return <img src={media} alt="country" />;
    } else {
      return <video src={media} loop muted autoPlay playsInline></video>;
    }
  }
  return (
    <div className="vertical_big_card" onClick={() => onNavigation(data.id)}>
      <div className="vertical_big_card_details transparent_div">
        <div className="vertical_big_card_details_content">
          <div className="vertical_big_card_details_content_header">
            {data?.title ? (
              <h1 className="vertical_big_card_details_content_header_title">
                {data?.title?.length > 21 ? (
                  <>{data?.title.substring(22, "") + "..."}</>
                ) : (
                  <>{data?.title}</>
                )}
              </h1>
            ) : (
              <h1 className="vertical_big_card_details_content_header_title">
                {data?.excursionTitle?.length > 31 ? (
                  <>{data?.excursionTitle.substring(32, "") + "..."}</>
                ) : (
                  <>{data?.excursionTitle}</>
                )}
              </h1>
            )}
            <p className="vertical_big_card_details_content_header_count">
              {data?.duration ? (
                <>
                  {data?.duration?.length > 19 ? (
                    <>{data?.duration.substring(20, "") + "..."}</>
                  ) : (
                    <>{data?.duration}</>
                  )}
                </>
              ) : (
                <>
                  {data?.excursionDuration > 19 ? (
                    <>{data?.excursionDuration.substring(20, "") + "..."}</>
                  ) : (
                    <>{data?.excursionDuration}</>
                  )}
                </>
              )}
            </p>
          </div>
          <div className="vertical_big_card_details_content_body">
            <div className="vertical_big_card_details_content_body_info">
              {data?.description ? (
                <p className="vertical_big_card_details_content_body_info_desc">
                  {data?.description?.length > 54 ? (
                    <>{data?.description.substring(55, "") + "..."}</>
                  ) : (
                    <>{data?.description}</>
                  )}
                </p>
              ) : (
                <p className="vertical_big_card_details_content_body_info_desc">
                  {data?.detailsDescription?.length > 54 ? (
                    <>{data?.detailsDescription.substring(55, "") + "..."}</>
                  ) : (
                    <>{data?.detailsDescription}</>
                  )}
                </p>
              )}
              {data?.price ? (
                <p className="vertical_big_card_details_content_body_info_price">
                  {data?.price?.length > 23 ? (
                    <>{data?.price.substring(24, "") + "..."}</>
                  ) : (
                    <>{data?.price}</>
                  )}
                </p>
              ) : (
                <p className="vertical_big_card_details_content_body_info_price">
                  {data?.detailsPrice?.length > 23 ? (
                    <>{data?.detailsPrice.substring(24, "") + "..."}</>
                  ) : (
                    <>{data?.detailsPrice}</>
                  )}
                </p>
              )}
            </div>
            <div className="vertical_big_card_details_content_body_action">
              <Button
                variant={"secondary"}
                name="Узнать подробнее"
                size={"full_btn"}
                onClick={onClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="vertical_big_card_image">{cardMedia()}</div>
    </div>
  );
};

export default ExcursionCard;
