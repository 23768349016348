import React from "react";
import "./SkiHolidayResorts.scss";
import { useNavigate } from "react-router-dom";

const SkiHolidayResorts = ({ count, skiCountries, countries }) => {
  const navigation = useNavigate();
  const onNavigation = (id) => {
    navigation(`/country/${id}`);
  };
  return (
    <div className="grid-container">
      {skiCountries ? (
        <>
          {skiCountries?.map((item, index) => (
            <div key={index} className="grid-item">
              <img src={item.flag} alt={item.name} className="flag" />
              <p> {item.name}</p>{" "}
              {count ? <p className="counting"> {item.count}</p> : null}
            </div>
          ))}
        </>
      ) : (
        <>
          {countries?.map((item, index) =>
            item?.active ? (
              <div
                key={index}
                className="grid-item"
                onClick={() => {
                  onNavigation(item.id);
                }}
              >
                <img src={item.flag} alt={item.name} className="flag" />
                {item.name.length > 12 ? (
                  <p> {item.name.substring(12, "") + "..."}</p>
                ) : (
                  <p>{item.name}</p>
                )}
                {count ? <p className="counting"> {item.count}</p> : null}
              </div>
            ) : null
          )}
        </>
      )}
    </div>
  );
};

export default SkiHolidayResorts;
