import React, { useEffect, useState } from "react";
import "./ToursInArmenia.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import FullWidthCard from "../../components/fullWidthCard/FullWidthCard";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import { NavLink, useParams } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
const ToursInArmenia = () => {
  const { id } = useParams();
  const [currentArmeniaTour, setCurrentArmeniaTour] = useState();
  const [armeniaTours, setArmeniaTours] = useState([]);

  async function getCurrentArmeniaTourData() {
    await $api
      .post(`/armenia/get-current-tour`, { id: id })
      .then((response) => {
        console.log(response.data, "turhay");
        setCurrentArmeniaTour(response.data);
      });
    await $api.get(`/armenia/get-authors-tours-data`).then((response) => {
      setArmeniaTours(
        response.data.map((item) => {
          return {
            name: item.title,
          };
        })
      );
    });
  }

  useEffect(() => {
    getCurrentArmeniaTourData();
  }, []);
  return (
    <div className="tours_in_armenia">
      <Helmet>
        <title>{currentArmeniaTour?.title}</title>
        <meta name="description" content="СКАЗОЧНЫЙ ОТДЫХ В АРМЕНИИ" />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={currentArmeniaTour?.title}
          backgrounds={currentArmeniaTour?.armCurrentTourBackgrounds}
          slider={
            currentArmeniaTour?.armCurrentTourBackgrounds?.length > 1
              ? true
              : false
          }
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink to="/armenia" className="home_nav">
            Армения
          </NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{currentArmeniaTour?.title}</NavLink>
        </div>
      </div>
      <div className="title_section">
        <TitleDesc title={currentArmeniaTour?.cardTitle} />
      </div>
      <div className="tours containerXL">
        {currentArmeniaTour?.armCurrentTourCardItems.map((item) => {
          return (
            <FullWidthCard
              key={item?.id}
              data={item}
              hideButton={true}
              size={"small"}
            />
          );
        })}
      </div>
      <div className="about_price">
        <div className="left_section">
          <div className="price_title_section">
            <h1>{currentArmeniaTour?.whatIncludes}</h1>
          </div>
          <ul>
            {currentArmeniaTour?.armCurrentTourIncludes.map((item, index) => {
              return (
                <li key={index}>
                  {" "}
                  <span> {item.text} </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="right_section">
          <div className="price_title_section">
            <h1>{currentArmeniaTour?.whatNotIncludes}</h1>
          </div>
          <ul>
            {currentArmeniaTour?.armCurrentTourNotIncludes?.map(
              (item, index) => {
                return (
                  <li key={index}>
                    {" "}
                    <span> {item?.text} </span>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
      <div className="form_section">
        <SelectTourForm
          variant={"white"}
          selects={true}
          splitLastOne={true}
          currentCountry={{ name: "АРМЕНИЯ" }}
          countryTours={armeniaTours}
        />
      </div>
    </div>
  );
};

export default ToursInArmenia;
