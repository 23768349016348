import React, { useEffect, useState } from "react";
import "./AllExcursions.scss";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import ExcursionCard from "../../components/excursionCard/ExcursionCard";
import DescSection from "../../components/descSection/DescSection";
import ServicesCard from "../../components/servicesCard/ServicesCard";
import SkiHolidayResorts from "../../components/skiHolodayResorts/SkiHolidayResorts";
import MoreInfoModal from "../../components/moreInfoModal/MoreInfoModal";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import $api from "../../http";
import { Helmet } from "react-helmet";
export const AllExcursions = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  const handleCardClick = (data) => {
    setModalData(data);
    setShowModal(true);
  };
  const [allExcursions, setAllExcursions] = useState();
  const [countries, setCountries] = useState();
  async function getAllExcursions() {
    await $api.get(`excursion/get-all-excursion-data`).then((response) => {
      setAllExcursions(response.data[0]);
    });
  }

  async function getCountriesForExcursions() {
    await $api.post(`/countrie`).then((response) => {
      setCountries(response.data);
    });
  }

  useEffect(() => {
    getAllExcursions();
    getCountriesForExcursions();
  }, []);
  return (
    <div className="all_excursions">
      <Helmet>
        <title>{allExcursions?.title}</title>
        <meta name="description" content={allExcursions?.description} />
      </Helmet>
      <div className="hero_sec">
        <HeroBgName
          name={allExcursions?.title}
          background={allExcursions?.allExcursionBackground?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{allExcursions?.title}</NavLink>
        </div>
      </div>
      <div className="container">
        <div className="desc_section">
          <DescSection text={allExcursions?.description} />
        </div>
        <TitleDesc title={allExcursions?.adrenalineTitle} />
        <div className="excursion_card_section">
          {allExcursions?.adrenalineCards?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={() => handleCardClick(item)}
              />
            );
          })}
        </div>
        <div className="desc_section">
          <DescSection text={allExcursions?.adrenalineDesc} />
        </div>
        <TitleDesc title={allExcursions?.unusualTitle} />
        <div className="excursion_card_section">
          {allExcursions?.unusualCards?.map((item, index) => {
            return (
              <ExcursionCard
                data={item}
                key={index}
                setShowModal={setShowModal}
                showModal={showModal}
                onNavigation={() => handleCardClick(item)}
              />
            );
          })}
        </div>
        <div className="desc_section">
          <DescSection text={allExcursions?.unusualDesc} />
        </div>
        <TitleDesc title={allExcursions?.gastroHolidayTitle} />
        <div className="world_excursions_card container">
          {allExcursions?.gastroCards?.map((item, index) => {
            return (
              <ServicesCard
                key={index}
                title={item.title}
                src={item.src || item.backgroundName}
              />
            );
          })}
        </div>
        <div className="desc_section">
          <DescSection text={allExcursions?.gastroHolidayDesc} />
        </div>
        <div className="country_section">
          <SkiHolidayResorts count={false} countries={countries} />
        </div>
      </div>
      <div className="modal_div">
        {showModal ? (
          <MoreInfoModal setShowModal={setShowModal} data={modalData} />
        ) : null}
      </div>
    </div>
  );
};
