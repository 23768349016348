import React from "react";

const ArrowRightIcon = () => {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99974 8.96661C7.82196 8.96661 7.64952 8.93328 7.48241 8.86661C7.3153 8.79994 7.17663 8.71105 7.06641 8.59994L0.933072 2.46661C0.688628 2.22217 0.566406 1.91105 0.566406 1.53328C0.566406 1.1555 0.688628 0.844387 0.933072 0.599943C1.17752 0.355498 1.48863 0.233276 1.86641 0.233276C2.24418 0.233276 2.5553 0.355498 2.79974 0.599943L7.99974 5.79994L13.1997 0.599943C13.4442 0.355498 13.7553 0.233276 14.1331 0.233276C14.5109 0.233276 14.822 0.355498 15.0664 0.599943C15.3108 0.844387 15.4331 1.1555 15.4331 1.53328C15.4331 1.91105 15.3108 2.22217 15.0664 2.46661L8.93307 8.59994C8.79974 8.73328 8.6553 8.82794 8.49974 8.88394C8.34418 8.93994 8.17752 8.9675 7.99974 8.96661Z"
        fill="#278084"
      />
    </svg>
  );
};

export default ArrowRightIcon;
