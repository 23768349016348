import React, { useEffect, useState } from "react";
import "./CruiseSection.scss";
import ExcursionCard from "../excursionCard/ExcursionCard";
import TitleDesc from "../TitleDesc/TitleDesc";
import Button from "../../command/button/Button";
import { useNavigate } from "react-router-dom";
import MoreInfoModal from "../moreInfoModal/MoreInfoModal";
const CruiseSection = ({ homeCruises, homeData }) => {
  const navigation = useNavigate();

  const handleAllCruises = () => {
    navigation(`/all-Cruises`);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showModal]);

  const handleCardClick = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  return (
    <div className="cruise_section container">
      <TitleDesc
        title={homeData?.titleOfTheFourthSection}
        description={homeData?.descriptionOfTheFourthSection}
      />
      <div className="cruise_card_section">
        {homeCruises.map((item, index) => {
          return (
            <ExcursionCard
              data={item}
              key={index}
              setShowModal={setShowModal}
              showModal={showModal}
              onNavigation={() => handleCardClick(item)}
            />
          );
        })}
      </div>
      <div className="button_section">
        <Button
          variant={"primary"}
          name="Все круизы"
          size={"large"}
          onClick={handleAllCruises}
        />
      </div>
      <div className="modal_div">
        {showModal ? (
          <MoreInfoModal setShowModal={setShowModal} data={modalData} />
        ) : null}
      </div>
    </div>
  );
};

export default CruiseSection;
