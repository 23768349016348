import React from "react";
import "./Button.scss";
const Button = ({ variant, name, size, onClick }) => {
  return (
    <div className="button_wrapper">
      <button className={`${variant} ${size}`} onClick={onClick}>
        {name}
      </button>
    </div>
  );
};

export default Button;
