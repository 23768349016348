import React, { useEffect, useState } from "react";
import "./Transfer.scss";
import transfer from "../../assets/image/transfer.png";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import pure from "../../assets/image/transport/pure.png";
import econom from "../../assets/image/transport/economy.png";
import comfort from "../../assets/image/transport/comfort.png";
import business from "../../assets/image/transport/business.png";
import premium from "../../assets/image/transport/premium.png";
import limousine from "../../assets/image/transport/limousine.png";
import suv from "../../assets/image/transport/suv.png";
import van from "../../assets/image/transport/van.png";
import bus from "../../assets/image/transport/bus.png";
import helicopter from "../../assets/image/transport/helicopter.png";
import airplane from "../../assets/image/transport/airplane.png";
import parcel from "../../assets/image/transport/parcel.png";
import cargo from "../../assets/image/transport/cargo.png";
import minibus from "../../assets/image/transport/minibus.png";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const Transfer = () => {
  const [transferData, setTransferData] = useState(null);
  const data = {
    bg: transfer,
    titile: "ТРАНСФЕР",
    desc: [
      "Lada Travel предлагает широкий выбор трансферов для путешественников по всему миру. Мы заботимся о комфорте и удобстве наших клиентов, предлагая им безопасные и надежные транспортные услуги.",
      "Независимо от вашего места назначения, наша команда готова обеспечить вам комфортное путешествие от аэропорта или вокзала до вашего отеля, достопримечательностей или любых других мест в вашем списке желаемых мест. Мы работаем с опытными и профессиональными водителями, которые внимательно следят за расписанием рейсов и гарантируют пунктуальность и безопасность во время вашего передвижения.",
      "Не нужно беспокоиться о поиске транспорта в незнакомой стране или городе – доверьтесь нам, и мы обеспечим вам удобный и беззаботный трансфер. Мы знаем, что ваше время важно, поэтому наши трансферы позволят вам максимально рационально использовать его и насладиться каждым мгновением вашего увлекательного путешествия.",
      "Благодаря нашим гибким тарифам и широкому выбору транспортных средств,мы можем предложить вам идеальное соотношение качества и стоимости. Расслабьтесь и наслаждайтесь поездкой, а организацию трансферов доверьтепрофессионалам – наша команда всегда готова сделать ваше путешествие максимально комфортным и беззаботным.",
    ],
    transport: [
      { name: "Мотоцикл", image: pure },
      { name: "Эконом", image: econom },
      { name: "Комфорт", image: comfort },
      { name: "Бизнес", image: business },
      { name: "Премиум", image: premium },
      { name: "VIP", image: limousine },
      { name: "Внедорожник", image: suv },
      { name: "Минивэн", image: van },
      { name: "Автобус", image: bus },
      { name: "Вертолёт", image: helicopter },
      { name: "Самолёт", image: airplane },
      { name: "Посылка", image: parcel },
      { name: "Крупногабаритный груз", image: cargo },
      { name: "Микроавтобус", image: minibus },
    ],
  };

  async function getTransferData() {
    await $api.get(`/transfer`).then((response) => {
      setTransferData(response.data);
    });
  }

  useEffect(() => {
    getTransferData();
  }, []);
  return (
    <div className="transfer_main">
      <Helmet>
        <title>{transferData?.title}</title>
        <meta name="description" content={transferData?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={transferData?.title}
          background={transferData?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{transferData?.title}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={transferData?.description} />
      </div>
      <div className="transfer_main_select_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
      <div className="transfer_main_transport">
        <div className="transfer_main_transport_header">
          <h3>Наш парк</h3>
        </div>
        <div className="transfer_main_transport_grid_container">
          {data.transport.map((item, index) => (
            <div key={index} className="grid-item">
              <div className="grid-item_image">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="grid-item_title">
                <p> {item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
