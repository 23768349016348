import React, { useEffect, useState } from "react";
import "./CorporativeVip.scss";
import HeroBgName from "../../components/heroBgName/HeroBgName";
import DescSection from "../../components/descSection/DescSection";
import SelectTourForm from "../../components/selectTourForm/SelectTourForm";
import TitleDesc from "../../components/TitleDesc/TitleDesc";
import $api from "../../http";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { Helmet } from "react-helmet";
const CorporativeVip = () => {
  const [corporativeVipData, setCorporativeVip] = useState(null);
  async function getCorporativeVipData() {
    await $api.get(`/recreation`).then((response) => {
      setCorporativeVip(response.data);
    });
  }

  useEffect(() => {
    getCorporativeVipData();
  }, []);
  return (
    <div className="corporative_main">
      <Helmet>
        <title>{corporativeVipData?.title}</title>
        <meta name="description" content={corporativeVipData?.description} />
      </Helmet>
      <div className="hero_div">
        <HeroBgName
          name={corporativeVipData?.title}
          background={corporativeVipData?.backgroundName}
        />
        <div className="breadcrumbs">
          <NavLink to="/" className="home_nav">
            Главная
          </NavLink>
          <ArrowRightIcon />
          <NavLink>Услуги</NavLink>
          <ArrowRightIcon />
          <NavLink className="last-link">{corporativeVipData?.title}</NavLink>
        </div>
      </div>
      <div className="description_section">
        <DescSection text={corporativeVipData?.description} />
      </div>
      <div className="corporative_main_corporative_tour">
        <TitleDesc title={corporativeVipData?.corporateToursTitle} />
        <div className="corporative_main_corporative_tour_content">
          <ul>
            {corporativeVipData?.corporateTours.map((item, index) => {
              return item?.text ? (
                <li key={index}>
                  {" "}
                  <span> {item?.text} </span>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      </div>
      <div className="corporative_main_tour_form">
        <SelectTourForm variant={"white"} selects={true} />
      </div>
    </div>
  );
};

export default CorporativeVip;
